import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import random from 'lodash.random'

import { H2 } from '../Headings'
import CTANewsletter from './Newsletter'
import CTAGetInTouch from './GetInTouch'

const possibleCTAs = {
  AcfOptions_Callstoaction_ctas_CtaContent_CtaNewsletter: CTANewsletter,
  AcfOptions_Callstoaction_ctas_CtaContent_CtaGetintouch: CTAGetInTouch,
}

const CallToAction = props => {
  // TODO Decide whether we’re removing the CTA entirely,
  // and remove get-ctaid-from-select function too, or add
  // new Call to Action to replace it.
  /* eslint-disable no-unreachable */
  return null

  return (
    <div>
      <div className="bg-magenta clearfix md-flex mt0">
        <div
          className="col-12 md-col-6 bg-gray bg-cover bg-center"
          style={{
            minHeight: '280px',
            backgroundImage: props.localFile
              ? `url(${props.localFile.childImageSharp.fluid.src})`
              : undefined,
          }}
        />
        <div className="col-12 md-col-6 p2 md-p3 lg-pb4 mb1 self-center">
          <div className="max-width-2 mb3 md-mb4">
            <H2 mt={0} mb={0} color="white">
              {props.title}
            </H2>
          </div>
          <div className="max-width-3">
            {props.ctaContent && props.ctaContent.length >= 1
              ? props.ctaContent.map((layoutItem, index) => {
                  return (
                    <div key={`CallToAction_Content_${index}`}>
                      {possibleCTAs[layoutItem.fieldGroupName] ? (
                        React.createElement(
                          possibleCTAs[layoutItem.fieldGroupName],
                          props
                        )
                      ) : (
                        <pre
                          className="p2 gray"
                          style={{ outline: '2px dashed' }}>
                          “{layoutItem.fieldGroupName}” doesn’t exist
                        </pre>
                      )}
                    </div>
                  )
                })
              : null}
          </div>
        </div>
      </div>
    </div>
  )
}

CallToAction.defaultProps = {
  title: 'Get in touch.',
}

CallToAction.propTypes = {
  title: PropTypes.string,
}

const CallToActionWrapper = props => (
  <StaticQuery
    query={graphql`
      {
        wp {
          acfOptions {
            callsToAction {
              ctaGallery {
                localFile {
                  prettySize
                  childImageSharp {
                    id
                    fluid(maxWidth: 1280) {
                      src
                    }
                  }
                }
              }
              ctas {
                title
                ctaContent {
                  ... on WpAcfOptions_Callstoaction_ctas_CtaContent_CtaNewsletter {
                    fieldGroupName
                  }
                  ... on WpAcfOptions_Callstoaction_ctas_CtaContent_CtaGetintouch {
                    fieldGroupName
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      let options = data.wp.acfOptions.callsToAction

      return (
        <CallToAction
          {...options.ctaGallery[random(0, options.ctaGallery.length - 1)]}
          {...options.ctas[props.ctaId]}
        />
      )
    }}
  />
)

CallToActionWrapper.defaultProps = {
  ctaId: 0,
}

CallToActionWrapper.propTypes = {
  ctaId: PropTypes.number.isRequired,
}

export default CallToActionWrapper
