import React from 'react'
import PropTypes from 'prop-types'

const Newsletter = props => {
  return (
    <form
      action={props.action}
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_blank"
      validate="validate"
      method="post"
      onSubmit={e => {
        // e.preventDefault()
        // console.log(e)
      }}>
      {/* TODO We need to make these not required in Mailchimp */}
      <label hidden>
        <strong>First Name</strong>
        <input type="hidden" name="FNAME" value="_" />
      </label>
      <label hidden>
        <strong>Last Name</strong>
        <input type="hidden" name="LNAME" value="_" />
      </label>
      <div className="flex items-end col-12 lg-col-8 border border-white bg-white">
        <label htmlFor="mce-EMAIL" className="block col-12">
          <strong className="hide">Email</strong>
          <input
            type="email"
            name="EMAIL"
            id="mce-EMAIL"
            required
            placeholder={props.placeholder}
            className="input border-white mb0"
            style={{ height: '2.5rem' }}
          />
        </label>
        <div
          style={{ position: 'absolute', left: '-5000px' }}
          aria-hidden="true">
          <input
            type="text"
            name="b_2007c0d14cc09b25f9f80f940_126e1b0fd3"
            tabIndex="-1"
            defaultValue=""
          />
        </div>
        <div>
          <input
            type="submit"
            value="Subscribe"
            id="mc-embedded-subscribe"
            className="btn bg-magenta white"
            style={{ height: '2.5rem' }}
          />
        </div>
      </div>
    </form>
  )
}

Newsletter.defaultProps = {
  action:
    'https://group.us18.list-manage.com/subscribe/post?u=2007c0d14cc09b25f9f80f940&amp;id=126e1b0fd3',
  placeholder: 'name@example.com',
}

Newsletter.propTypes = {
  action: PropTypes.string.isRequired,
}

export default Newsletter
