import React from 'react'
import PropTypes from 'prop-types'

const Address = props => {
  return (
    <address className="mb1">
      {props.unit ? `${props.unit}-` : ''}
      {props.streetAddress}
      <br />
      {props.city}, {props.province}{' '}
      {props.postalCode ? (
        <span>
          <br />
          {props.postalCode}
        </span>
      ) : null}
    </address>
  )
}

Address.defaultProps = {}
Address.propTypes = {
  unit: PropTypes.string,
  streetAddress: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  province: PropTypes.string.isRequired,
  postalCode: PropTypes.string,
}

export default Address
