const doNotTrack = function () {
  let result = false

  if (navigator) {
    if (navigator.msDoNotTrack) {
      // IE9 and 10
      result = navigator.msDoNotTrack
    } else if (navigator.doNotTrack) {
      if (navigator.doNotTrack === 'yes') {
        // Firefox <32
        result = true
      } else if (navigator.doNotTrack === 'unspecified') {
        // Firefox <32
        result = false
      } else {
        result = navigator.doNotTrack
      }
    }
  } else if (window && window.doNotTrack) {
    // Safari 6.1.1–9.1.3
    result = window.doNotTrack
  }

  return result
}

module.exports = doNotTrack
