import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import doNotTrack from '../../utils/do-not-track'
import isDev from '../../utils/is-dev'
import Address from '../Address'

class GetInTouch extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      city: null,
      country: null,
      region: null,
    }
  }

  componentDidMount() {
    let track = !doNotTrack()

    if (
      track ||
      (isDev())
    ) {
      this.setRoughLocation()
    }
  }

  setRoughLocation() {
    let token = '25b59a02cfe87f'

    if (typeof window !== 'undefined' && window.fetch) {
      if (!navigator.userAgent.match(/bot|spider/i)) {
        // It’s not a bot
        // https://ipinfo.io/developers/filtering-bot-traffic

        window
          .fetch(`https://ipinfo.io/geo?token=${token}`)
          .then(function (res) {
            return res.json()
          })
          .then(json => {
            // We don’t want these
            delete json.ip
            delete json.loc

            // If they aren’t from Canada, we don’t want these either
            if (json.country !== 'CA') {
              json.city = null
              json.region = null
            }

            this.setState({
              city: json.city, // Ex. Vancouver
              region: json.region, // Ex. British Columbia
              country: json.country, // Ex. CA
            })
          })
          .catch(err => console.warn(err))
      }
    }
  }

  getContactsBySlug() {
    let contactsBySlug = {}

    this.props.locations.edges.forEach(({ node }) => {
      contactsBySlug[node.slug] = node
    })

    return contactsBySlug
  }

  // Brittle, basic location conditional
  guessOfficeFromLocation() {
    const state = this.state
    let offices = this.getContactsBySlug()

    if (offices) {
      let cygnusLocationSlug = 'toronto' // Ontario by default
      let possibleOffices = offices

      if (state.country === 'CA') {
        let currentCityAsSlug = state.city ? state.city.toLowerCase() : ''

        // If the actual city matches, ex. Victoria
        if (possibleOffices[currentCityAsSlug]) {
          cygnusLocationSlug = currentCityAsSlug
        } else if (
          state.region === 'Alberta' ||
          state.region === 'Manitoba' ||
          state.region === 'Saskatchewan'
        ) {
          cygnusLocationSlug = 'calgary'
        } else if (state.region === 'British Columbia') {
          cygnusLocationSlug = 'vancouver'
        }

        if (possibleOffices[cygnusLocationSlug]) {
          return possibleOffices[cygnusLocationSlug]
        }
      }

      // If the default option we guessed is actually in
      // the offices, return that, otherwise return the first item
      return possibleOffices[Object.keys(possibleOffices)[0]]
    }
  }

  render() {
    let office = this.guessOfficeFromLocation()

    if (office) {
      let contact = office.locationDetails.primaryContact

      return (
        <div className="white sm-flex">
          <div className="col-12 sm-col-6">
            <strong className="block">{contact.name}</strong>
            <div className="mb2">
              {contact.role}
              {office.locationDetails.address
                ? `, ${office.locationDetails.address.city}`
                : null}
            </div>
            {office.locationDetails.phone_number ? (
              <div>
                Phone:{' '}
                <a
                  className="white"
                  href={`tel:${office.locationDetails.phone_number}`}>
                  {office.locationDetails.phone_number}
                </a>
              </div>
            ) : null}
          </div>
          <div className="col-12 sm-col-6">
            <div className="mb2">
              {office.locationDetails.address ? (
                <Address {...office.locationDetails.address} />
              ) : null}
            </div>
            {office.locationDetails.email ? (
              <div>
                Email:{' '}
                <a
                  className="white"
                  href={`mailto:${office.locationDetails.email}`}>
                  {office.locationDetails.email}
                </a>
              </div>
            ) : null}
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

GetInTouch.defaultProps = {}
GetInTouch.propTypes = {
  locations: PropTypes.object.isRequired,
}

const GetInTouchWrapper = props => (
  <StaticQuery
    query={graphql`
      {
        locations: allWpLocation {
          totalCount
          edges {
            node {
              id
              title
              slug
              locationDetails {
                address {
                  unit
                  streetAddress
                  city
                  province
                }
                faxNumber
                phoneNumber
                primaryContact {
                  name
                  role
                }
              }
            }
          }
        }
      }
    `}
    render={data => <GetInTouch locations={data.locations} />}
  />
)

export default GetInTouchWrapper
